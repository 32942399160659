import logo from './logo.svg';
import './App.css';
import { useCallback, useEffect, useState } from 'react';
import Web3 from 'web3';
import erc721 from './ether/erc721.json';
import mt from './ether/merkle.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@material-ui/core';
const merkle = require('@openzeppelin/merkle-tree');
const {StandardMerkleTree} = merkle;
const web3 = new Web3(window.ethereum);
const contract = new web3.eth.Contract(erc721.abi,erc721.address);
const mainnet = '1'

function App() {
  const [account,SetAccount] = useState('');
  const [isLoading,SetIsLoading] = useState(true);
  const [isMainNet,SetIsMainNet] = useState(window.ethereum.networkVersion === mainnet);
  const [minted,SetMinted] = useState(0);
  const [canMint,SetCanMint] = useState(0);
  const [total,SetTotal] = useState(0);
  const [isWhiteList,SetIsWhiteList] = useState(false);

  const getTotal = async() => {
    SetTotal(await contract.methods.totalSupply().call());
  }

  useEffect(()=>{
    getTotal();
    if(window.ethereum){
      window.ethereum.request({method:'eth_requestAccounts'})
        .then(res=>{
          SetIsMainNet(window.ethereum.networkVersion === mainnet);
          SetAccount(res[0]);
          SetIsLoading(false);
        })
    }else{
      alert("install metamask extension!!")
    }
    window.ethereum.on('accountsChanged', function (accounts) {
      SetAccount(accounts[0]);
    })
    
    window.ethereum.on('networkChanged', function (networkId) {
      console.log(networkId === mainnet)
      if (networkId === mainnet){
        SetIsMainNet(true);
      }else{
        SetIsMainNet(false);
      }
    })
  }
  ,[])

  const getMintStatus = useCallback(async () => {
    const nowMinted = await contract.methods.minted_per_address(account).call();
    const canMint = await contract.methods.mint_per_address(account).call();
    const mtLoad = StandardMerkleTree.load(mt);
    let iwl = false;
    for (const [i, v] of mtLoad.entries()) {
      if (v[0].toLowerCase() === account.toLowerCase()) {
        iwl = true;
      }
    }
    SetIsWhiteList(iwl);
    SetMinted(nowMinted);
    SetCanMint(canMint === '0' ? 1 : canMint);
  },[account]);

  useEffect(()=>{
    if(account !== ''){
      getMintStatus();
    }
  },[account])

  const mint = useCallback(async(count)=>{
    const mtLoad = StandardMerkleTree.load(mt);
    let proof;
    for (const [i, v] of mtLoad.entries()) {
      if (v[0].toLowerCase() === account.toLowerCase()) {
        proof = mtLoad.getProof(i);
      }
    }
    if (proof){
      contract.methods.mintPreSaleNFT(count,proof).send({from:account}).then((res)=>{
        getTotal();
        getMintStatus();
      })
    }else{
      toast("You are not whitelist.");
    }
  },[account])

  if (isLoading){
    return (
      <div className="largeDivCoin">
        <div style={{
          justifyContent: "center",
          alignItems: "center",
          display:'flex',
          flexDirection:'column',
          flexGrow: 1,
          paddingTop:50,
        }}>
          <CircularProgress/>
          <h1>Please Connect MetaMask Wallet.</h1>
        </div>
      </div>
    )
  }
  return (
    <div className="largeDivCoin">
        {isMainNet ? <>
        {account === '' ? 
        <div style={{
          justifyContent: "center",
          alignItems: "center",
          display:'flex',
          flexGrow: 1,
          paddingTop:50,
        }}>
          <h1>Please connect my MetaMask wallet.</h1>
        </div> : 
        <div style={{display:"flex",flexDirection:'column',
        flexGrow: 1,}}>
        <div style={{
          display:"flex",
        flexDirection:'row-reverse',}}>
          <div style={{
            display:"flex",
            color:"white",
            paddingLeft:40,
            paddingRight:40,
            paddingTop:20,
            paddingBottom:20,
            width:"fit-content",
            marginRight:'5%',
            borderColor:"white",
            borderWidth:0.5,
            borderStyle:'solid',
            borderRadius:30,
          }}>
          {account.substring(0,6)}...{account.substring(36)}
          </div>
        </div>
       <div style={{
         justifyContent: "center",
         alignItems: "center",
         display:'flex',
         flexGrow: 1,
         backgroundColor:'rgb(255,105,2)',
         paddingBottom:100,flexDirection:'column'
       }}>
         <div>
         <div style={{
           textAlign:'center',
           lineHeight:2,
         }}>
            <br/>
            <img src="https://justlabs.s3.ap-northeast-2.amazonaws.com/1.png" style={{height:300,width:300}}></img>
            <br/>
            <h1>OrangeHare Zero</h1>
            <h2>Quantity : {total} / 500</h2>
          </div>
          <div style={{
           marginTop: 100,
           marginBottom:100,
           textAlign:'center',
         }}>
          <span style={{fontSize:20}}>
          {isWhiteList ? "You can mint "+(canMint - minted)+" NFTs." : "You are not whitelist."}
          </span>
          </div>
          <div onClick={()=>{mint(1)}} style={{width:300,height:50,borderStyle:'solid',borderColor:"white",borderWidth:0.5,display:"flex",justifyContent:"center",alignSelf:'center',alignItems:"center",cursor:"pointer",marginTop:25}}>
            <span style={{alignSelf:'center'}}>1</span>
          </div>
          <div onClick={()=>{mint(2)}} style={{width:300,height:50,borderStyle:'solid',borderColor:"white",borderWidth:0.5,display:"flex",justifyContent:"center",alignSelf:'center',alignItems:"center",cursor:"pointer",marginTop:25}}>
            <span style={{alignSelf:'center'}}>2</span>
          </div>
          <div onClick={()=>{mint(3)}} style={{width:300,height:50,borderStyle:'solid',borderColor:"white",borderWidth:0.5,display:"flex",justifyContent:"center",alignSelf:'center',alignItems:"center",cursor:"pointer",marginTop:25}}>
            <span style={{alignSelf:'center'}}>3</span>
          </div>
        </div>
        </div>
        </div>
        }
        </> :  <div style={{
          justifyContent: "center",
          alignItems: "center",
          display:'flex',
          flexGrow: 1,
          paddingTop:50,
          textAlign:'center',
          flexDirection:'column',
        }}
        >
          <CircularProgress/>
          <h1>Please change the network.</h1></div>}
        <ToastContainer/>
      </div>
  );
}

export default App;
